import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

const DomainMenu = () => (
  <StaticQuery
    query={graphql`
      {
        hope {
          allDomains(orderBy: "order") {
            name
            color
          }
        }
      }
    `}
    render={data => (
      <DropdownButton className="domainMenu" title="Change Domain" variant="outline-secondary">
        {data.hope.allDomains.map(domain => (
          <Dropdown.Item key={domain.name} style={{ color: domain.color }}>
            <Link to={`/domain/${domain.name.replace(/(\W+)/gm, '-').toLowerCase()}`}>
              {domain.name}
            </Link>
          </Dropdown.Item>
        ))}
      </DropdownButton>
    )}
  />
);

export default DomainMenu;

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { last, first } from 'lodash';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import parse from 'html-react-parser';

import Layout from '../components/layout';
import Map from '../components/graphics/map';
import SectionButtons from '../components/page/sectionButtons';
import DomainMenu from '../components/page/domainMenu';
import IndicatorGrid from '../components/page/indicatorGrid';
import { colorSchemes, classedChoropleth, extractObject } from '../components/util/choropleth';

import '../styles/domain.css';

export const query = graphql`
  query domainPage($id: ID!) {
    hope {
      Domain(where: { id: $id }) {
        name
        color
        overview
        definition
        footnote
        topTakeaway
        bottomTakeaway
        regionalSummary
        bottom: score(sortBy: score_ASC, first: 5) {
          state {
            name
          }
        }
        top: score(sortBy: score_DESC, first: 5) {
          state {
            name
          }
        }
        score(sortBy: score_DESC) {
          state {
            abbreviation
          }
          score
        }
      }
    }
  }
`;

export default function Domain({ data }) {
  const {
    hope: {
      Domain: {
        name,
        color,
        definition,
        footnote,
        regionalSummary,
        topTakeaway,
        bottomTakeaway,
        top,
        bottom,
        score,
      },
    },
  } = data;

  const values = classedChoropleth(score, color, 20);

  return (
    <Layout>
      <Container>
        <Row noGutters>
          <Col>
            <h1>HOPE Data by Domain</h1>
            <SectionButtons />
          </Col>
        </Row>
        <Row style={{ alignItems: 'center' }} noGutters>
          <Col lg={4} md={6} sm={12}>
            <h3 style={{ color }}>{name}</h3>
          </Col>
          <Col md={6} sm={12}>
            <DomainMenu />
          </Col>
          <Col md={6} sm={12}>
            {parse(definition)}
          </Col>
        </Row>
        <Row noGutters>
          <Map
            title="Domain Summary Score"
            summary="The domain summary score is an average of each state’s indicator scores within the domain. It measures how far a state has to go to meet the HOPE Goal (Distance to Goal) and how much variation there is across racial and ethnic groups within the state (Racial Inequity). Domain summary scores can range from 0 to 100 with higher scores indicating better combined performance."
            values={extractObject(values, 'score')}
            colors={extractObject(values, 'color')}
            scheme={[
              last(colorSchemes[color.toLowerCase()]),
              first(colorSchemes[color.toLowerCase()]),
            ]}
            legendValues={[0, 100]}
          />
        </Row>
        <hr />
        <Row noGutters>
          <Col lg={4}>
            <h3>Domain Summary</h3>
          </Col>
          <Col lg={8}>
            <h4>Regional Patterns</h4>
            <p>{regionalSummary}</p>
          </Col>
        </Row>
        <hr />
        <Row noGutters>
          <Col className="text" md={6}>
            <h3>Top 5 Performing States</h3>
            <ol>
              {top.map(t => (
                <li key={t.state.name}>{t.state.name}</li>
              ))}
            </ol>
            <h4>Top Takeaways</h4>
            {parse(topTakeaway)}
          </Col>
          <Col className="text" md={6}>
            <h3>Bottom 5 Performing States</h3>
            <ol>
              {bottom.map(t => (
                <li key={t.state.name}>{t.state.name}</li>
              ))}
            </ol>
            <h4>Top Takeaways</h4>
            {parse(bottomTakeaway)}
          </Col>
          {footnote && (
            <Col sm={12}>
              <div className="footnote">{parse(footnote)}</div>
            </Col>
          )}
        </Row>
      </Container>
      <p>&nbsp;</p>
      <IndicatorGrid domains />
    </Layout>
  );
}

Domain.propTypes = {
  data: PropTypes.shape().isRequired,
};
